#sidebar-wrapper {
    min-height: 100vh;
    margin-left: 0;
    transition: margin 0.25s ease-out;
    background-color: $dark-blue;
    position: relative;

    @include sm {
        margin-left: -15rem;
    }
}

#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
    width: 232px;
}

#page-content-wrapper {
    min-width: 0;
    width: 100%;
    background: #F6F8F8;
    padding: 10px 30px;
    position: relative;
    max-height: 100vh;
    overflow-y: auto;
    @include sm {
        min-width: 100vw;
    }
    &.fullPage {
        padding: 0;
    }
}

.sidebar__logo-icon {
    display: none;
}

.sidebar__logo-image {
    display: initial;
}

body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
    // margin-left: -15rem;
    transition: width 0.25s ease-out;
    width: 61px;

    .list-group, .sidebar__content {
        width: 61px;
    }

    .sidebar__logo-icon {
        display: flex;
    }

    .sidebar__logo-image {
        display: none;
    }

    .sidebar__option {
        flex-wrap: wrap;
        align-items: center;
        font-size: 6px;
        line-height: 10px;
        justify-content: center;
        text-align: center;
        img {
            margin-right: 0;
        }
    }

    @include sm {
        margin-left: 0;
    }
}

.sidebar__option {
    background-color: transparent !important;
    color: white !important;
    border: none;
    display: flex;
    align-items: center;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    img {
        margin-right: 0.5rem;
    }

    &.active {
        background-color: $blue !important;

        img {
            filter: brightness(0) invert(1);
        }
    }

    &:hover {
        background-color: $blue !important;
        color: white;

        img {
            filter: brightness(0) invert(1);
        }
    }
}

.sidebar__content {
    width: 232px;
    flex-wrap: wrap;
    min-height: 85vh;
    justify-content: space-between;
}

.button__sidebar-toggle {
    background: #FFFFFF;
    border: 1px solid #E6E7E9;
    box-sizing: border-box;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    width: 20px;
    height: 20px;
    position: absolute;
    left: 220px;
    top: 5%;
    color: $custom-grey;
    border-radius: 100%;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    i {
        margin-right: 2px;
    }

    @include sm {
        left: 10px;
    }
}

.sb-sidenav-toggled {
    .button__sidebar-toggle {
        left: 50px;
    }
}