.mappings__box-card {
    box-sizing: border-box;
    border-radius: 10px 10px 0px 0px;
    height: 307px;
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    .row {
        justify-content: space-between;
        height: 60%;
    }
}

.mappings__box-card-title {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: $custom-grey;
}

.mappings__box-card-desc {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: $custom-grey;
}

.mapping__features__modal__content {
    background: #FFFFFF;
    border: 1px solid #E6E7E9;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
}

.mapping__features-modal-title {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: $dark-blue;
}

.mappings__label {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: $custom-grey;
    min-width: 180px;
    text-align: right;
}