.dropdown-menu.show {
  left: -150px;
  background: #ffffff;
  border: 1px solid #cad5e0;
  box-sizing: border-box;
  box-shadow: 0px 7px 15px rgb(0 0 0 / 15%);
  border-radius: 8px;
  width: 225px;
  padding: 10px;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
}

#basic-nav-dropdown {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: $custom-black;

  &:after {
    margin-left: 10px;
    content: "\f078";
    font-family: "Font Awesome 5 Free";
    border: none;
    vertical-align: middle;
    color: #91a4b7;
    display: none;
  }
}

.navbar__arrow-down {
  position: absolute;
  top: -7px;
  right: 40px;
}

.navbar__avatar-container {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-size: cover;
}

.navbar__item {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: $dark;
  padding: 7px 5px;
}

.navbar__email {
  padding: 7px 5px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: $light-grey-variant-2;
}

.nav-tabs .nav-item {
  padding-left: 20px;
  padding-right: 20px;

  @include sm {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.nav-tabs .nav-link {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #585858;
  border: none;
  border-bottom: 3px solid transparent;
  padding-left: 0;
  padding-right: 0;
  padding-left: 5px;
  padding-right: 5px;

  @include sm {
    font-size: 13px;
    line-height: 18px;
  }
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: $dark-blue;
  border: none;
  border-bottom: 3px solid #585858;
  padding-left: 5px;
  padding-right: 5px;

  @include sm {
    font-size: 13px;
    line-height: 18px;
  }
}

.dropdown-dot {
  font-size: 9px;
  color: $green;
  vertical-align: middle;
  &.inactive {
    color: $light-grey-variant-2;
  }
}

.navbar__plus-btn {
  color: $blue;
  background: none;
  border: none;
  padding: 0;
  margin-top: 0px;
}

.navbar__search-box-wrapper.accounts__search-box-wrapper {
  .input-group-text {
    border-radius: 15px;
  }
  .navbar__search-box {
    border-radius: 15px;
  }
}
