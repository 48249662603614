.form-control {
    height: 35px;
    border: 1px solid $light-grey;
    border-radius: 4px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $dark-grey;
    &:focus {
        outline: none;
        box-shadow: none;
    }
}

select.form-control {
    appearance: auto;
}

.tab-content .form-control {
    border-color: #CAD5E0;
    border-radius: 8px;
}

.form-text {
    font-size: 8px;
}

.forms__checkbox-lg {
    input {
        height: 33px;
        width: 33px;
    }

    label {
        line-height: 40px;
        margin-left: 5px;
    }
}