.org__search-box {
  border-left: 2px solid $light-grey;
  border-top: 1px solid $light-grey;
  border-bottom: 1px solid $light-grey;
}

.org__people-box {
  display: flex;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid $light-grey;
  position: relative;
  &:hover {
    cursor: pointer;
    background-color: $light-grey;
    .org__drag-icon {
      display: block;
    }
  }
}

.org__title {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 20px;
  color: $dark-blue;
  display: block;
}

.org__occupation {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 20px;
  color: #91a4b7;
  display: block;
}

.org__mail {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 20px;
  color: $custom-grey;
  display: block;
}

.border-yellow {
  border-left: 2px solid $yellow;
}

.border-green {
  border-left: 2px solid $green;
}

.org__drag-icon {
  position: absolute;
  left: 5px;
  display: none;
}

.org__card {
  border: 1px solid #cad5e0;
  box-sizing: border-box;
  border-radius: 5px;
  width: 244px;
  min-height: 154px;
  margin: auto;
  position: relative;
}

.org__card-header {
  background: #cad5e0;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 20px;
  color: $custom-grey;
  padding: 6px;
  i {
    margin-top: 5px;
    color: #fff;
  }
}

.org__card-body {
  padding: 10px;
  padding-bottom: 0;
}
.org__user-info {
  display: flex;
  align-items: center;
}

.org__social-network {
  font-size: 10px;
  color: #0a66c2;
}

.org__details {
  p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    line-height: 10px;
    color: $custom-grey;
    margin: 0;
    span {
      color: #91a4b7;
    }
  }
}

.org__toggle-section {
  button {
    background: none;
    border: none;
    padding: 0;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    line-height: 10px;
    color: #cad5e0;
  }
}

.org__edge-btn {
  position: absolute;
  right: 0;
  bottom: 0;
  background: none;
  border: 1px solid #cad5e0;
  border-radius: 5px 0px;
  color: #cad5e0;
  font-size: 10px;
}

.deals__badge {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 6px;
  line-height: 13px;
  width: 34px;
  height: 13px;
  padding: 0;
}

.org__ellipsis-text {
  max-width: 85px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.org__card-green {
  border-color: $green;
  .org__card-header {
    background: $green;
  }
  .org__toggle-section {
    button {
      color: $green;
    }
  }
}

.org__toggle-section-content {
  border-top: 1px solid #cad5e0;
  padding-top: 10px;
  h5 {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    line-height: 10px;
    color: $dark-blue;
  }
  p {
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    font-size: 6px;
    line-height: 10px;
    color: $custom-grey;
    margin-bottom: 5px;
  }
  ul {
    list-style: none;
    padding: 0;
    li {
      border: 1px solid #cad5e0;
      box-sizing: border-box;
      border-radius: 5px;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 8px;
      line-height: 10px;
      color: $dark-blue;
      padding: 6px 10px;
      margin-bottom: 5px;
      position: relative;
      &:hover {
        cursor: pointer;
        background-color: $light-grey;
        .org__drag-icon {
          display: block;
          left: 3px;
        }
      }
    }
  }
}
