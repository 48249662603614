.workspace__label {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $custom-grey;
}

.workspace__actions-btn {
    background-color: white;
    border-radius: 8px;
    width: 33px;
    height: 33px;
    border: 1px solid #CAD5E0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $custom-grey;
    margin-left: 10px;
}

.workspace__number-span {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: $custom-grey;
}

.workspace__label-form {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $custom-grey;
    margin-bottom: 10px;
}