$primary: #04C9EB;
$dark: #515352;
$dark-grey: #4A4D4E;
$light-grey: #E6E7E9;
$dark-blue: #021626;
$blue: #449AEC;
$custom-black: #0D1829;
$light-grey-variant-2: #ABABAB;
$custom-grey: #585858;
$green: #51BE95;
$red: #EC1F5A;
$orange: #F77307;
$grey-400: #91A4B7;
$purple: #8678FF;
$yellow: #FFBA69;
// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;