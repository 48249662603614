* {
    font-family: 'Inter', sans-serif;
    margin: 0px;
}

html,
body {
    min-height: 100vh;
    min-width: 100vw;
    overflow-x: hidden;
}

.pointer {
    cursor: pointer;
}

.base__main-title {
    color: $dark-blue;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    margin-top: -3%;
    @include sm {
        margin-top:10px;
    }
}

tspan {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $custom-grey;
    opacity: 0.6;
    letter-spacing: 0.44px;
}

.custom-divider {
    background-color: $light-grey;
    opacity: 1;
    width: 98%;
    margin: auto;
}

.table__td-blue {
    color: $blue !important;
}

.no-padding {
    padding: 0px !important;
}

.bg-green {
    background-color: $green !important;
}

.bg-purple {
    background-color: $purple !important;
}

.bg-yellow {
    background-color: $yellow !important;
}

.bg-grey {
    background-color: $grey-400 !important;
}

.pac-container {
    z-index: 10100 !important;
}