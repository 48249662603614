.analytics__card-header {
    border-bottom: none !important;
    padding-bottom: 0;
}

.analytics__card-body {

    .nav-tabs .nav-link.active,
    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link {
        font-size: 14px !important;
    }

    .nav-tabs .nav-item {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.analytics__expecation-container {
    .intro {
        p {
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 20px;
            color: $custom-grey;
        }
    }

    .form-check-label {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $custom-grey;
    }

    .form-check-input:checked {
        background-color: $green;
        border-color: $green;
    }
}

.analytics__bold-title {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: $custom-grey;
}

.analytics__blue-title {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $blue;
}

.analytics__card {
    @include sm {
        padding: 10px;
    }

    .help__card-title {
        @include sm {
            font-size: 16px;
        }
    }

    .nav-tabs {
        @include sm {
            margin-left: 0 !important;
        }

    }

    .nav-tabs .nav-item {
        @include sm {
            padding-left: 0;
            padding-right: 0;
        }

        .nav-link {
            @include sm {
                font-size: 12px !important;
            }

        }
    }
}