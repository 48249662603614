.calendar-screen {
    height: 75vh;
}

.rbc-toolbar .rbc-toolbar-label {
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 20px;
    color: #000000;
}

.rbc-toolbar button {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #4A4D4E;
}

.rbc-event-content {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
}

.rbc-event-label {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    color: #021626;
}

.rbc-day-slot .rbc-event {
    border-radius: 5px;
    padding-top: 5px;
}

.rbc-header>a>span,
.rbc-header>span {
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
    text-transform: uppercase;
}

.rbc-label {
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
}

.home__schedule-card {
    border: 1px solid #E6E7E9;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 15px;
}

.home__schedule-card-title {
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 20px;
    color: #000000;
}

.home__fab-button {
    font-size: 20px;
    color: #FF708B;
    opacity: 1;
}

.home__search-box {
    width: 206px;
}