.dashboard__card {
    background: $light-grey;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    max-width: 321px;
    margin-left: 20px;
}

.dashboard__card-content {
    border: 1px solid $light-grey;
    box-sizing: border-box;
    border-radius: 0px 0px 5px 5px;
    background: white;
    padding: 10px 15px;
}

.dashboard__card-figure {
    padding: 25px;
    padding-bottom: 0;
    position: relative;
    cursor: pointer;
}

.dashboard__card-img-wrapper {
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
    border-radius: 5px 5px 0px 0px;
    padding: 15px;
    text-align: center;

    h6 {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 8px;
        line-height: 20px;
        text-align: left;
    }

    img {
        max-width: 100%;
    }
}

.dashboard__card-title {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $custom-grey;
    margin-bottom: 0;
}

.dashboard__card-subtitle {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: $light-grey;
    text-transform: uppercase;
    margin-bottom: 0;
}

.dashboard__overlay {
    position: absolute;
    inset: 0;
    background: rgba(8, 20, 38, 0.57);
    border-radius: 5px 5px 0px 0px;
    width: 100%;
    height: 100%;
    display: none;
    padding: 15px 5px;
}

.dashboard__card-figure:hover .dashboard__overlay {
    display: block;
}

.dashboard__title {
    color: $custom-grey;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
}

.dashboard__charts-bg {
    width: 100%;
    background-color: white;
    padding: 25px;
    border: 1px dashed #CAD5E0;
    margin: 10px;
    cursor: pointer;
    height: 660px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &:hover .dashboard__charts-overlay {
        display: flex;
    }

    @include sm {
        height: fit-content;
    }
}

.dashboard__charts-overlay {
    display: none;
    position: absolute;
    inset: 0;
    background: rgba(2, 22, 38, 0.57);
    border-radius: 4px;
    align-items: center;
    justify-content: center;
}

.dashboard__modal-title {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $dark-blue;
}

.dashboard__modal-body {
    padding: 25px;

    .form-control,
    select,
    .form-control-sm {
        border: 1px solid #CAD5E0;
        border-radius: 5px;
    }

    .form-check-label {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: $custom-grey;
    }
}

.dashboard__form-label {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: $custom-grey;
    margin-bottom: 5px;
}

.dashboard__chart-card {
    background: #FFFFFF;
    border: 1px solid #E6E7E9;
    box-sizing: border-box;
    box-shadow: 0px 2.76726px 5px rgba(218, 218, 218, 0.25);
    border-radius: 10px;
}

.dashboard__chart-card-header {
    background-color: white;
    padding: 20px;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;

    @include sm {
        display: block !important;
    }
}

.dashboard__chart-card-title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: $custom-grey;
    margin-bottom: 0;

    &.green {
        color: $green;
        font-weight: 400;
    }

    &.red {
        color: $red;
        font-weight: 400;
    }
}

.dashboard__card-body {
    width: 100%;
    height: 600px;
}

.dashboard__chart-dates {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $custom-grey;
    opacity: 0.6;
}

.dashboard__dropdown {
    .dropdown-item {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: $custom-grey;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}