.schedule__modal-dialog {
  max-width: 466px;

  .modal-content {
    border: 1px solid $light-grey;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    border-top: 6px solid #ff708b;
  }

  .modal-header {
    border-radius: 0;
  }

  .schedule__event-title-input {
    border: none;
    border-bottom: 1px solid #cad5e0;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #91a4b7;
    &::placeholder {
      color: #91a4b7;
    }
  }

  i {
    color: #91a4b7;
    font-size: 20px;
  }

  label {
    display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #91a4b7;
  }
  .react-datetime-picker__wrapper {
    border: 0 !important;
  }

  .schedule__material-input {
    padding: 0;
    border: 0;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    padding-left: 10px;
    color: #000000;
    &::placeholder {
      color: #91a4b7;
    }
    &:focus {
      background: $light-grey;
      border-radius: 5px 5px 0px 0px;
      border-bottom: 2px solid #ff708b;
    }
  }

  .schedule__material-input--active {
    background: $light-grey;
  }

  .schedule__outline-btn {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #91a4b7;
  }

  .schedule__btn-save {
    background: #ff708b;
    border-radius: 6px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: white;
  }

  .schedule__calendar {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 291px;
  }
  .react-calendar__tile--active {
    background-color: #ff708b !important;
    border-radius: 50%;
  }

  .react-calendar__tile--now {
    background-color: $light-grey !important;
    border-radius: 50%;
  }

  .react-datetime-picker__inputGroup {
    background: $light-grey;
    border-radius: 5px 5px 0px 0px;
    border-bottom: 2px solid #ff708b;
  }

  .dropdown-toggle {
    background: #ffffff !important;
    border-radius: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #91a4b7 !important;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    &::placeholder {
      color: #91a4b7;
    }
    &:focus {
      background: $light-grey !important;
      border-radius: 5px 5px 0px 0px;
      border-bottom: 2px solid #ff708b !important;
    }
    &::after {
      display: none;
    }
  }

  .dropdown-item {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #021626;
    padding: 10px 15px;
  }

  .dropdown-menu {
    padding: 0;
  }
}

.suggestions {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  position: absolute;
  width: 300px;
  list-style: none;
  padding: 0;
  margin-top: 10px;
  z-index: 99;
  em {
    padding: 5px 15px;
    font-size: 10px;
    padding-bottom: 15px;
  }
}

.suggestion-item {
  cursor: pointer;
  padding-left: 15px;
  img {
    width: 32px;
    height: auto;
    margin-right: 5px;
  }
  display: flex;
  align-items: center;
  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #021626;
    margin-bottom: 0;
  }
  span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #021626;
  }
  &:hover {
    background-color: $light-grey;
  }
}

.suggestion-active {
  background-color: $light-grey;
}

.suggestion-item-added {
  padding: 10px;
  position: relative;
  &:hover {
    background-color: $light-grey;
    .suggestion-options {
      display: block;
    }
  }
}

.suggestion-options {
  position: absolute;
  right: 10px;
  display: none;
  button {
    background: none;
    border: none;
    cursor: pointer;
    i {
      font-size: 14px !important;
    }
  }
}

.schedule__conference-btn {
  background: #ff708b;
  border-radius: 5px;
  color: #ffffff !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  img {
    margin-right: 10px;
  }
}

.schedule__conference-link {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  color: #91a4b7;
  margin-left: 10px;
}

.schedule__wrapper-editor {
  background: #e6e7e9;
  border-radius: 5px 5px 0px 0px;
  border-bottom: 2px solid #ff708b;
}

.schedule__toolbar-editor {
  background: #e6e7e9 !important;
  border: none !important;
  padding: 0 10px;
  .rdw-dropdown-wrapper,
  .rdw-option-wrapper {
    background: #e6e7e9 !important;
    border: none !important;
  }
}

.schedule__attachment-item {
  /* White */
  background: #ffffff;

  /* Text Box outline */
  border: 1px solid #cad5e0;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: auto;
  max-width: 261px;
  padding: 10px;
  margin-bottom: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #021626;
  display: flex;
  align-items: center;
  i {
    color: $red;
  }
}
