.app_tracking--mgt-btn {
  height: 32px;
  padding: 0;
}

.app-tracking__tag-btn {
  background: transparent;
  border-radius: 7px 0px 0px 7px;
  border: 1px solid #91a4b7;
  height: 32px;
  &.active {
    background: #e6e7e9;
  }
}

.app-tracking__tag-btn-variant {
  background: transparent;
  border-radius: 0px 7px 7px 0px;
  border: 1px solid #91a4b7;
  height: 32px;
  &.active {
    background: #e6e7e9;
  }
}

.app-tracking__table {
  border: 1px solid #e6e7e9;
  border-radius: 3px;
  thead {
    background: #f6f8f8;
    th {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      color: $custom-grey;
    }
  }

  tbody {
    td {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      color: $custom-grey;
    }
    tr {
        border-bottom: 1px solid #E6E7E9;
    }
  }
}
