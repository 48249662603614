.tabs__variant-2-content {
  background: #fff;
  min-height: 87vh;
  margin-left: -30px;
  margin-right: -30px;
  padding: 30px;
}


.tabs__variant-2 {
    &.nav-tabs {
        .nav-link {
            font-size: 12px;
            color: $custom-grey;
            font-weight: normal;
        }
    }
}

.tabs__variant-1-sm {
    &.nav-tabs{
        .nav-link {
            font-size: 12px;
            color: $custom-grey;
            line-height: 20px;
        }
    }
}