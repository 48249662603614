.button__blue {
    background-color: $primary;
    border-radius: 4px;
    color: #FFFFFF;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    letter-spacing: -0.4px;
    transition: all 0.3s ease-in-out;

    &:hover {
        color: $primary;
        background-color: transparent;
        border: 1px solid $primary;
    }
}

.button__dark-blue {
    background-color: $blue;
    border-radius: 8px;
    min-width: 142px;
    width: auto;
    height: 40px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: white !important;
}

.button-green {
    color: $green;
    background: none;
    height: 40px;
    width: 105px;
    border: 1px solid $green;
    border-radius: 8px;
    font-size: 14px;
}

.button-red {
    color: $red;
    background: none;
    height: 40px;
    min-width: 105px;
    border: 1px solid $red;
    border-radius: 8px;
    font-size: 14px;
}

.button-blue {
    color: $blue !important;
    background: none;
    height: 40px;
    width: 105px;
    border: 1px solid $blue;
    border-radius: 8px;
    font-size: 14px;
}

.button-lightblue {
    color: $primary;
    background: none;
    height: 40px;
    width: 105px;
    border: 1px solid $primary;
    border-radius: 8px;
    font-size: 14px;
}

.button-purple {
    color: $purple;
    background: none;
    height: 40px;
    width: 105px;
    border: 1px solid $purple;
    border-radius: 8px;
    font-size: 14px;
}

.btn-orange {
    background-color: $orange;
    border: 1px solid $orange;
    color: white;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 20px;
    border-radius: 8px;

    &:hover {
        background-color: white;
        color: $orange;
    }
}

.btn-orange-outline {
    border: 1px solid $orange;
    color: white;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 20px;
    border-radius: 8px;
    color: $orange;

    &:hover {
        background-color: $orange;
        color: white;
    }
}

.btn-blue-outline {
    color: $blue;
    border: 1px solid $blue;
    border-radius: 8px;
    padding: 10px 24px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    &:hover {
        background-color: $blue;
        color: white;
    }
}

.btn-solid-red {
    background-color: $red;
    border: 1px solid $red;
    color: white;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 20px;
    border-radius: 8px;

    &:hover {
        background-color: white;
        color: $red;
    }
}

.btn-red-outline {
    border: 1px solid $red;
    color: white;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 20px;
    border-radius: 8px;
    color: $red;

    &:hover {
        background-color: $red;
        color: white;
    }
}

.btn__trash {
    color: white !important;
}

.btn-white-outline {
    color: white;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;


    &:hover {

        background-color: #FFFFFF;
        color: $blue;
    }
}

.buttons__link {
    color: $blue !important;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
}

.btn-solid-green {
    background-color: $green;
    border-radius: 6px;
    color: white;

    &:disabled {
        opacity: 0.5;
    }
}

.button_icon-only {
    background: none;
    border: none;
    color: $custom-grey;
    opacity: 0.6;
}

.button__outline {
    background: transparent;
    border: none;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $blue;
}

.button__gray {
    background-color: $grey-400;
}