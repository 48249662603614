.messages-container {
  background: #fff;
  height: 89.8vh;
  margin-left: -30px;
  margin-right: -30px;
}

.messages__sidebar-header {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: $custom-grey;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  padding-top: 12px;
}

.messages__sidebar-actions {
  svg {
    cursor: pointer;
  }
}

.messages__sidebar {
  border-right: 1px solid $light-grey;
  height: 89.8vh;
  position: relative;
}

.messages__folder {
  ul {
    list-style: none;
    padding: 10px 0;
    margin: 0;
    li {
      width: 100%;
      padding: 10px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: $custom-grey;
      cursor: pointer;
      &.active {
        background: rgba($color: #91a4b7, $alpha: 0.15);
        border-radius: 0px 3px 0px 0px;
        font-weight: 600;
      }
    }
  }

  hr {
    width: 180px;
    margin: 1rem auto;
  }
}

.messages__connect-btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: $blue;
  display: block;
  margin: 0 auto;
}

.messages__sidebar-footer {
  width: 100%;
  align-self: flex-end;
  position: absolute;
  bottom: 10px;

  .messages__connect-btn {
    margin: 0;
    padding: 0;
  }
}

.messages__settings {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: $custom-grey;
  img {
    width: 30px;
  }
}

.messages__inbox,
.messages__chat {
  border-right: 1px solid $light-grey;
  height: 89.8vh;
}

.messages__inbox-header {
  display: flex;
  align-items: center;
  padding: 0 10px;

  select {
    border: none;
    color: $blue;
    width: 120px;
  }
}

.messages__list {
  overflow-x: hidden;
  overflow-y: auto;
  height: 85vh;
}

.messages__list-item {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: $custom-grey;
  border-top: 1px solid $light-grey;
  padding: 10px;
  cursor: pointer;
  img {
    width: 20px;
  }
  &.active {
    background: rgba($color: $blue, $alpha: 0.1);
  }
  &:hover {
    background: rgba($color: $blue, $alpha: 0.1);
  }
}

.messages__time {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
}

.messages__user {
  img {
    width: 30px;
  }
  svg {
    margin-right: 8px;
    margin-bottom: 10px;
  }
}

.messages__chat-header {
  padding: 6px 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 18px;
  color: $custom-grey;
  border-bottom: 1px solid $light-grey;
  img {
    width: 30px;
  }
  .messages__connect-btn {
    margin: 0;
    padding: 0;
  }
}

.messages__chat-closed-btn {
  border-radius: 5px;
  background: $light-grey;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: $custom-grey;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.messages__chat-more-btn {
  border-radius: 5px;
  background: $light-grey;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: $custom-grey;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.messages__chat-body {
  height: 55vh;
  overflow-x: hidden;
  overflow-y: auto;
  background: rgba($color: #91a4b7, $alpha: 0.15);
}

.messages__date {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 18px;
  color: $custom-grey;
}

.messages__chat-item {
  img {
    width: 30px;
  }
}

.messages__chat-item-body {
  border: 1px solid $light-grey;
  border-radius: 5px;
  background-color: #fff;
  padding: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: $custom-grey;
  h3 {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;

    span {
      font-weight: 400;
      margin-left: 5px;
    }
    svg {
      margin-right: 5px;
      vertical-align: sub;
    }
  }
  p {
    margin: 0;
  }
}

.messages__send-box {
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: $custom-grey;
  }
}

.messages__textarea {
  textarea {
    height: 19vh;
    border: none;
  }
}

.messages__editor {
  width: 100%;
  background: rgba($color: #91a4b7, $alpha: 0.15);
  padding: 10px;

  button {
    background: none;
    border: none;
    color: $custom-grey;
    font-size: 14px;
    &.messages__insert-btn {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: $blue;
      i {
        color: $custom-grey;
      }
    }
    &.messages__send-btn {
      border-radius: 5px;
      background: $blue;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #fff;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      width: 55px;
      height: 32px;
      padding: 0;
    }
    &.messages__send-options-btn {
      border-radius: 5px;
      background: $blue;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #fff;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      width: 25px;
      height: 32px;
      padding: 0;
      margin-left: 1px;
    }
  }
}
