.help__card-title {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: $dark-blue;
}

.help__subtitle {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $dark-blue;
    margin-bottom: 0;
}