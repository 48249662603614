
.integrations__container{
    width: 70%;
    float: left;

    @include sm {
        width: 100%;
        text-align: center;
    }

    .form-check-input {
        height: 25px;
        width: 60px;
    }
}

.integrations__description{
    color: $custom-grey;
    font-size: 14px;
}

.segment-png{
    height: 50px;
    width: 50px;
}

.integrations__custom-card h3{
    font-size: 18px;
    font-weight: 600;
    color: $custom-grey;
}

.integrations__custom-card p{
    font-size: 16px;
    color: $custom-grey;
}

.integrations__bordered{
    border-bottom:2px solid #E9EAEB;
    
}


.pendo-png{
    height: 50px;
    width: 50px;
}



.amplitude-png{
    height: 50px;
    width: 50px;
}

.salesforce-png{
    height: 50px;
    width: 50px;
}