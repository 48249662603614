.deals__btn-cta {
  min-width: auto !important;
}

.deals__settings-btn {
  button {
    color: $grey-400 !important;
    border: 1px solid $grey-400;
    border-radius: 8px;
    height: 40px;
    background-color: transparent !important;
    &:after {
      margin-left: 5px;
      content: "\f078";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      border: none;
      vertical-align: middle;
      color: $grey-400;
    }
  }

  .dropdown-menu {
    width: auto;
  }

  .dropdown-item {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: $custom-grey;
  }
}

.deals__column {
  background: #f6f8f8;
  min-height: 450px;
  border: 1px solid #cad5e0;
  padding: 0;

  .deals__closed-details {
    display: none;
  }

  &:first-child {
    border-bottom-left-radius: 5px;
    .deals__column-header {
      color: #ff708b;
    }

    .deals__closed-details {
      display: block;
    }

    .deals__extra-details {
      display: none;
    }
  }

  &:last-child {
    border-bottom-right-radius: 5px;
    background: rgba($color: $green, $alpha: 0.15);
    .deals__extra-details,
    .deals__toggle-details {
      display: none;
    }
  }
}

.deals__column-header {
  background: #ffffff;
  border-bottom: 1px solid #cad5e0;
  height: 39px;
  width: 100%;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $green;
  display: flex;
  align-items: center;
  justify-content: center;
}

.deals__column-step {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  background-color: $green;
  height: 30px;
  width: 100%;
  position: relative;
}

.deals__step-triangle-base {
  position: absolute;
  right: -18px;
  z-index: 2;
  @include sm {
    display: none;
  }
}

.deals__step-triangle {
  position: absolute;
  right: -15px;
  z-index: 5;
  @include sm {
    display: none;
  }
}

.deals__step-wrapper {
  padding: 0;

  .col {
    width: 100%;
    border-right: 1px solid #fff;

    &:first-child {
      .deals__column-step {
        border-top-left-radius: 5px;
        background-color: #ff708b;
      }
    }

    &:last-child {
      border: none;

      .deals__column-step {
        border-top-right-radius: 5px;
      }
    }
  }
}

.deals__card {
  border: 1px solid #cad5e0;
  box-sizing: border-box;
  border-radius: 5px;
  background: #ffffff;
  padding: 7px;
  position: relative;
}

.deals__card-header {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: $blue;
  line-height: 18px;
}

.deals__card-body {
  margin-top: 5px;

  span {
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: $custom-grey;
    display: block;
    margin: 2px 5px;
    &.deals__amount {
      font-weight: bold;
      color: $custom-grey;
    }

    &.deals__account-info-header {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 8px;
      line-height: 12px;
      color: $grey-400;
    }
    &.deals__toggle-details {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 8px;
      line-height: 10px;
      color: #91a4b7;
      text-align: center;
      margin-top: 10px;
    }

    &.deals__action {
      font-weight: bold;
      color: $green;
    }

    label.green {
      color: $green;
    }

    label.red {
      color: $red;
    }
  }
}

.deals__switch {
  label {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: $dark-blue;
    margin-left: 5px;
    margin-top: 2px;
  }
  .form-check-input:checked {
    background-color: $green;
    border-color: $green;
  }
}

.deals__view3-btn {
  background: none;
  border-radius: 0px 7px 7px 0px;
  border: 1px solid $grey-400;
  width: 40px;
  height: 40px;
  &.deals__view3-btn--active {
    background: $light-grey;
  }
}

.deals__view2-btn {
  background: none;
  border: none;
  border-top: 1px solid $grey-400;
  border-bottom: 1px solid $grey-400;
  width: 40px;
  height: 40px;
  &.deals__view2-btn--active {
    background: $light-grey;
  }
}

.deals__view1-btn {
  background: none;
  border-radius: 7px 0px 0px 7px;
  border: 1px solid $grey-400;
  width: 40px;
  height: 40px;
  &.deals__view1-btn--active {
    background: $light-grey;
  }
}

.deals__btn-filters {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: $custom-grey;
  background: none;
  border: none;
}

.deals__main-wrapper {
  .myAccounts__select {
    padding: 0;
    select {
      &:focus {
        outline: none;
        box-shadow: none;
      }
      font-size: 12px;
    }
  }
}

.deals__modal-dialog--add-deal {
  width: 376px;
  float: right;
  background: #ffffff;
  box-shadow: -5px 0px 10px rgba(0, 0, 0, 0.15);
  &.deals__modal-dialog--automate {
    .modal-header {
      background-color: $green;
    }

    .form-control {
      background: transparent;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      border-left: none;
    }
  }
  .modal-header {
    background-color: $blue;
    border: none;
    .modal-title {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
    }

    .btn-close {
      width: 15px;
      height: 15px;
      opacity: 1;
      filter: brightness(0) invert(1);
      font-size: 12px;
    }
  }

  label {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 20px;
    color: $custom-grey;
  }

  .form-control {
    background: #f6f8f8;
    border: 1px solid $light-grey;
    border-radius: 3px;
  }

  .mw-auto {
    min-width: auto;
  }

  .modal-footer {
    background: #f6f8f8;
    border: none;
  }
}

.deals__backdrop {
  opacity: 0 !important;
}

.deals__btn-add {
  background: none;
  border: none;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 20px;
  color: $blue;
  i {
    color: $custom-grey;
  }
}

.deals_board-settings {
  border: 1px solid #e6e7e9;
  border-radius: 10px;
}

.deals__settings-text {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 20px;
  color: $custom-grey;
}

.deals__settings-selector {
  display: flex;
  align-items: center;
  border: 1px solid #e6e7e9;
  border-radius: 5px;
  padding: 5px 15px;
  span {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: $custom-grey;
    margin-right: 10px;
  }

  select {
    background: #f6f8f8;
    border: 1px solid #e6e7e9 !important;
    box-sizing: border-box;
    border-radius: 3px !important;
    width: auto;
    min-width: 148px;
    height: 28px;
    font-size: 12px;
  }
}

.deals__settings-btn-variant-2 button {
  background: #f6f8f8 !important;
  border: 1px solid #e6e7e9;
  box-sizing: border-box;
  border-radius: 8px;
  width: 58px;
  height: 28px;
  padding: 0;
  &:after {
    margin-left: 5px;
    content: "\f078";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    border: none;
    vertical-align: middle;
    color: $grey-400;
  }
}

.deals__board-table-header {
  background: #f6f8f8;
  border: 1px solid #e6e7e9;
  box-sizing: border-box;
  border-radius: 3px 3px 0px 0px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 20px;
  color: $custom-grey;
  padding: 5px;
}

.deals-edit-btn {
  float: right;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 20px;
  color: $custom-grey;
  background: #f6f8f8;
  border: 1px solid #e6e7e9;
  box-sizing: border-box;
  border-radius: 3px;
  height: 28px;
  padding: 0;
  width: 93px;
  display: none;
}

.deals__board-table-items {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: $custom-grey;
  border: 1px solid #e6e7e9;
  border-top: none;
  padding: 0;
  overflow: hidden;
  .row {
    padding: 10px;
  }
  .form-control:disabled {
    background: #f6f8f8;
    border: 1px solid #e6e7e9;
    box-sizing: border-box;
    border-radius: 3px;
    color: $custom-grey;
    font-weight: normal;
    font-size: 12px;
  }
  select {
    max-width: 93px;
  }
  i {
    color: $custom-grey;
    margin-top: 10px;
  }

  &.selectable {
    :hover {
      background: #f6f8f8;
      .deals-edit-btn {
        display: block;
      }
    }
  }
}

.deals__settings-title {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $custom-grey;
  margin-bottom: 0;
}

.deals__workflow .col {
  padding: 0;
  .deals__workflow-item {
    height: 50vh;
    background: #f6f8f8;
    border: 1px solid #e6e7e9;
    overflow-y: auto;
  }

  &:first-child .deals__workflow-item {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  &:last-child .deals__workflow-item {
    border-bottom-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.deals__workflow-item-title {
  border-bottom: 1px solid #e6e7e9;
  padding: 10px;
  span {
    font-size: 8px;
    line-height: 20px;
    color: $custom-grey;
  }

  h2 {
    font-size: 10px;
    line-height: 20px;
    color: $custom-grey;
    text-transform: uppercase;
  }
}

.deals__workflow-btn {
  background: none;
  border: none;
  box-shadow: none;
  font-size: 10px;
  line-height: 20px;
  color: $custom-grey;
}

.deals__workflow-body {
  padding: 10px;
}

.deals__actions-title {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: $custom-grey;
}

.deals__action-btn {
  border: 1px solid $green;
  border-radius: 5px;
  background: transparent;
  width: 100%;
  display: flex;
  height: 40px;
  align-items: center;
  padding: 0;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: $custom-grey;
  margin-bottom: 5px;
  &__icon {
    width: 39px;
    background: #ff708b;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 20px;
    margin-right: 10px;
  }
}

.deals__action-box {
  border: 1px solid #51be95;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px #51be95;
  border-radius: 5px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 20px;
  color: #cad5e0;
}

.deals__vertical-separator {
  width: 2px;
  background: #E6E7E9;
  height: 100px;
  margin: auto;
}