.circle-green {
    color: $green;
}

.displays__inner-tabs.nav-tabs .nav-link {
    font-size: 14px;
    line-height: 20px;
}

.displays__inner-tabs.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: $blue;
    border-color: $blue;
}

.displays__small-card-header {
    padding: 15px;

    h5 {
        margin: 0;
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        color: $custom-grey;
    }
}

.displays__small-card-body {
    label {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $custom-grey;
    }
}

.displays__customers-stats {
    height: 180px;
    display: flex;
    align-items: center;
    justify-self: center;

    .stats {
        text-align: center;
        width: 100%;

        h1 {
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            font-size: 40px;
            line-height: 40px;
            color: $custom-grey;
        }

        h3 {
            font-family: Inter;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 20px;
            color: $custom-grey;
        }

        p {
            font-family: Inter;
            font-style: italic;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: $custom-grey;
        }
    }
}

.displays__content-label {
    margin: 0;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: $custom-grey;
}

.displays__custom-link {
    color: $blue;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-decoration: none;
}

.displays__empty-chart {
    height: 317px;
    border-color: $green;
    img {
        max-width: 100%;
        max-height: 100%;
    }
}