.tokens__description {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $custom-grey;
}

.tokens__custom-modal {
    max-width: 438px;
}

.tokens__custom-modal-content {
    border: 1px solid #E6E7E9;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 15px;
}

.tokens__bg-orange {
    background: lighten($color: $orange, $amount: 40);
    border: 1px solid $orange;
    padding: 15px;
    p {
        text-align: justify;
        margin-bottom: 0;
    }
}

.tokens__custom-modal-variant-2 {
    max-width: 539px;
}

.tokens__bg-red {
    background: lighten($color: $red, $amount: 40);
    border: 1px solid $red;
    padding: 15px;
    p {
        text-align: justify;
        margin-bottom: 0;
    }
}