.account__bold-message {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: $custom-grey;
}

.account__profile-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.account__custom-text {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: $custom-grey;
}

.account__button {
  color: $blue;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  &:first-child {
    padding-left: 0;
  }
}

.accounts__card-title {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: $custom-grey;
  margin-bottom: 0;
  span {
    font-weight: 200;
  }
}

.accounts__search-box {
  height: 32px;
  max-width: 100%;
  border: 1px solid #cad5e0;
  border-radius: 6px;
  border-left: none;

  &::placeholder {
    color: $light-grey;
  }
}

.accounts__search-box-wrapper {
  width: 257px;
  margin-bottom: 0 !important;

  .input-group-text {
    border-radius: 15px;
    background: none;
    border-right: none;
    color: #91a4b7;
    padding-right: 0;
    background-color: #ffffff;
  }

  .form-control {
    border-radius: 15px;
  }

  @include sm {
    margin-top: 10px;
  }
}

.accounts__filter-label {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $custom-grey;
}

.accounts__chart-wrapper {
  width: 100%;
  height: 292px;
}

.accounts__table {
  border-top: 3px solid $light-grey;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: $custom-grey;

  thead {
    background-color: #f6f8f8;

    th {
      border-bottom: none;
      font-weight: 500 !important;
    }
  }

  tr:nth-child(even) {
    background-color: #f6f8f8;
  }

  tbody {
    border-top: none !important;
  }

  .table__green-field {
    color: $green;
  }

  a {
    color: $blue;
    text-decoration: none;
  }

  .table__green-btn {
    width: 64px;
    height: 22px;
    background-color: $green;
    color: white;
    text-align: center;
    border-radius: 10px;
    line-height: 22px;
  }
}

.accounts__list-label {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: $custom-grey;
}

.accounts_segments-table {
  thead {
    background: #f6f8f8;
    border: 1px solid $light-grey;

    th {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: $custom-grey;
    }
  }

  tbody {
    border-top: none !important;

    td {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: $custom-grey;
      padding: 20px 0.5rem;

      a {
        color: $blue;
        text-decoration: none;
      }
    }
  }
}

.accounts__segments-title {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: $custom-grey;
}

.accounts__custom-dropdown > button {
  border: 1px solid #cad5e0 !important;
  border-radius: 6px;
  background-color: white !important;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: $custom-grey !important;
  padding: 0.375rem 20px;

  &::after {
    content: "\f078";
    font-family: "Font Awesome 5 Free";
    border: none;
    vertical-align: middle;
    margin-left: 0.5em;
  }
}

.accounts__custom-dropdown {
  .dropdown-menu {
    a {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: $custom-grey;

      &.active {
        background-color: $light-grey;
      }
    }
  }
}

.accounts__overview-card {
  label {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: black;
  }
  select {
    background: none;
    border: none;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: $green;
    .pending {
      color: $red;
    }
  }
}

.accounts__link {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $blue;
  text-decoration: none;
}

.accounts__overview-card-box {
  border: 1px solid $light-grey;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  height: 120px;
  padding: 15px;
  width: 258px;
  max-width: 100%;
  margin-bottom: 20px;
  position: relative;

  &.accounts__red-card {
    border-color: $red;
    h3 {
      color: $red;
    }
  }

  @include sm {
    margin: 0 auto;
    margin-bottom: 20px;
  }

  h4 {
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.44px;
    color: #021626;
    margin: 0;
  }

  h1 {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 44px;
    letter-spacing: -0.44px;
    color: #021626;
    margin: 0;

    .increment {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.44px;
      color: $green;
    }
  }

  p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #021626;
    mix-blend-mode: normal;
    opacity: 0.6;
    margin: 0;
    white-space: pre-wrap;
  }

  h6 {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #021626;
    margin: 0;
  }

  h3 {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #021626;
    margin: 5px 0;
    white-space: pre-wrap;
  }
}

.accounts__ellipsis_opt {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #cad5e0;
}

.accounts__general-card {
  border: 1px solid $light-grey;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 15px;

  p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #021626;
    mix-blend-mode: normal;
    opacity: 0.6;
  }
}

.accounts__general-card-title {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.44px;
  color: #021626;
}

.red-flags-list {
  list-style: none;
  padding-left: 10px;

  li {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 10px;
    color: #021626;

    i {
      color: $red;
      margin-right: 5px;
    }
  }
}

.accounts__chart-wrapper-sm {
  width: 100%;
  height: 145px;
}

.accounts__card-wrapper {
  display: flex;

  @include sm {
    flex-wrap: wrap;
  }
}

.accounts__card-cta {
  width: 25%;

  @include sm {
    width: 100% !important;
  }
}

.myAccounts__filterby p {
  font-size: 14px;
  font-weight: 400;
  color: $custom-grey;
  margin-top: 2px;
}

.myAccounts__select select {
  width: 139px;
  height: 33px;
  border-radius: 8px;
  border: 1px solid #cad5e0;
  color: $custom-grey;
  font-size: 14px;
  font-weight: 500;
}

.myAccounts__month {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $custom-grey;
}

.accounts__accordion-header {
  .accordion-button {
    display: inline;

    &:not(.collapsed) {
      background: none;
      box-shadow: none;
    }

    &.collapsed {
      i.fa-chevron-right {
        transform: rotate(0deg);
      }
    }

    p {
      font-size: 14px;
      font-weight: 600;
      color: $custom-grey;
      margin: 0;
      padding-right: 4px;

      @include sm {
        font-size: 12px;
      }
    }

    span {
      font-size: 14px;
      font-weight: 400;
      color: $custom-grey;

      @include sm {
        font-size: 12px;
      }
    }

    i.fa-chevron-right {
      color: $blue;
      margin-right: 5px;
      transform: rotate(90deg);
    }

    .note__content {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: $custom-grey;
      margin-left: 1rem;
    }
  }
}

.accounts__accordion-item {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px !important;
  margin: 10px auto;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1) !important;
}

.accounts__accordion-body {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $custom-grey;
  margin-left: 1rem;
}

.accounts__email-sender-box {
  display: flex;
}

.accounts__email-sender-box-img-wrapper {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.accounts__email-info-name {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: $custom-grey;
  margin: 0;
}

.accounts__email-info-receiver {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $custom-grey;
}

.accounts__action-call {
  label {
    font-size: 14px;
    font-weight: 400;
    color: $custom-grey;
    margin-bottom: 5px;
  }

  select {
    min-width: 139px;
    height: 33px;
    border-radius: 8px;
    border: 1px solid #cad5e0;
    font-size: 14px;
    font-weight: 500;
    color: $custom-grey;
  }
}

.accounts__task-icon-uncompleted {
  font-size: 25px;
  color: $red;
  margin-right: 10px;
}

.accounts__task-item {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.accounts__task-icon-completed {
  font-size: 25px;
  color: $green;
  margin-right: 10px;
}

.accounts__attachment-item {
  background: #f6f8f8;
  border: 1px solid #cad5e0;
  box-sizing: border-box;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 15px;

  i {
    color: #91a4b7;
    margin-right: 10px;
  }

  .accounts__attachment-title {
    color: $blue;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-right: 10px;
  }

  .accounts__attachment-size {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #91a4b7;
  }

  .accounts__attachment-date {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #91a4b7;
    float: right;

    @include sm {
      display: block;
      float: none;
    }
  }
}

.accounts__deal-card {
  border: 1px solid rgba($color: #000000, $alpha: 0.1);
  box-sizing: border-box;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 15px;
}

.accounts__deal-body {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $custom-grey;
}

.accounts__tabs-wrapper {
  .nav-item {
    background: #f6f8f8;
    border-top: 1px solid $light-grey;
    border-right: 1px solid $light-grey;
    padding-left: 0 !important;
    padding-right: 0 !important;
    &:first-child {
      border-top-left-radius: 5px;
      border-left: 1px solid $light-grey;
    }
    &:last-child {
      border-top-right-radius: 5px;
      border-right: 1px solid $light-grey;
    }

    .nav-link {
      padding: 0.5rem 15px;
      min-width: 99px;
      border: none !important;

      &.active {
        background-color: #ffffff !important;
      }
    }
  }
}

.accounts__crm-box-wrapper {
  position: absolute;
  right: 15px;

  @include sm {
    display: none;
  }
}

.accounts__sidebar {
  height: 100vh;
  border-right: 1px solid $light-grey;
  background-color: #f6f8f8;
  z-index: 2;
  max-width: 78px;
  &__item {
    background: #f6f8f8;
    border-bottom: 1px solid $light-grey;
    border-right: 1px solid $light-grey;
    height: 60px;
    width: 78px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 3;
    cursor: pointer;
    &.active {
      background-color: #ffffff;
      border-right: none;
    }
    span {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 9px;
      line-height: 20px;
      color: $custom-grey;
      display: block;
    }
    img {
      width: 20px;
    }
  }
}

.accounts__custom-dropdown button {
  height: 32px !important;
  width: 58px !important;
  padding: 0 !important;
}

.accounts__wrapper {
  background-color: #ffffff;
  border-top: 1px solid $light-grey;
  min-height: 100vh;
  margin-left: -30px;
  margin-right: -30px;
  overflow-x: hidden;
}

.accounts__main-title {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: $custom-grey;
}

.accounts__search {
  width: 180px;
}

.accounts__main-options {
  align-items: center;
  justify-content: end;
  button {
    width: 30px;
    height: 30px;
    border-color: $light-grey;
    &.button__dark-blue {
      min-width: 104px;
      height: 34px;
      font-size: 12px;
    }
  }
}

.accounts__main-wrapper {
  border: 1px solid $light-grey;
  border-radius: 5px;
  min-height: 90vh;
  .myAccounts__select {
    select {
      color: #91a4b7;
    }
  }

  ul.list-inline {
    li {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: $custom-grey;
      margin-right: 20px;
      i {
        color: $green;
      }
    }
  }

  table {
    thead {
      background-color: #f6f8f8;
      border-bottom: 1px solid $light-grey;
      th {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 20px;
        color: $custom-grey;
      }
    }
    tbody {
      border-top: none !important;
      td,
      th {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 20px;
        color: $custom-grey;
      }

      th {
        color: $blue;
      }
      tr.highlighted {
        background: rgba($color: #ff708b, $alpha: 0.1);
      }
    }
  }
}

.accounts__main-play-text {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  color: $blue;
}
