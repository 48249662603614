.login__content {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  @include sm {
    height: auto;
  }
}

.login__small-text {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  color: $dark-grey;
  margin-top: -12px;
  margin-left: 20px;
  letter-spacing: -0.5px;

  @include sm {
    font-size: 14px;
  }
}

.login__btn-google {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.4px;
  color: lighten($color: $dark, $amount: 10) !important;
  border: 1px solid rgba(86, 87, 88, 0.16);
  border-radius: 4px;
  width: 339px;
  height: 48px;

  @include sm {
    width: 300px;
  }
}

.login__separator-text {
  width: 48px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.02em;
  color: rgba(24, 24, 25, 0.42);
}

.login__label {
  color: $dark;
  font-size: 12px;
  margin-bottom: 10px;
}

.login__forgot-password {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: -0.5px;
  text-decoration-line: underline;
  color: lighten($color: $dark, $amount: 30);
}

.login__light-text {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.5px;
  color: lighten($color: $dark, $amount: 30);

  a {
    color: $primary;
  }
}

.login__privacy-text {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  letter-spacing: -0.4px;
  color: $dark;
}

.login__form,
.login__custom-container {
  @include sm {
    width: 100%;
    min-width: 300px;
  }
}

.login__top-navigation {
  padding: 15px 30px;

  @include sm {
    padding: 15px;
  }
}

.login__forgot-title {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.4px;
  color: $dark;
  text-align: left;
}

.login__text-forgot {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: -0.4px;
  color: $dark;
}

.login__confirmation-toast {
  position: fixed;
  top: 0;
  width: 100%;
  background: $primary;
  left: 0;
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 10px;
  color: white;
}
