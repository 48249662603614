.tasks__segments {
  .accordion-item {
    border: none;
  }

  .accordion-button {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $custom-grey;
    padding: 10px;
    padding-left: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: none !important;
    &::after {
      width: 12px;
      height: 12px;
      background-size: 12px;
      position: absolute;
      left: 0;
    }
  }
  .accordion-button:not(.collapsed) {
    background: transparent;
    box-shadow: none;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: $custom-grey;
      padding: 7px 10px;
      cursor: pointer;
      img {
        width: 20px;
        margin-right: 5px;
      }
      &.active,
      &:hover {
        background: rgba($color: #91a4b7, $alpha: 0.15);
        border-radius: 0px 3px 0px 0px;
      }
    }
  }
}

.tasks__sidebar {
  hr {
    width: 180px;
    margin: 1rem auto;
  }
}

.tasks__segment-add-btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #91a4b7;
}

.tasks__current-header {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: $custom-grey;
  padding: 10px 15px;
  img {
    width: 30px;
    margin-right: 5px;
  }
  span {
    font-size: 12px;
    font-weight: 400;
    margin-left: 15px;
    i {
      color: #ff708b;
    }
  }
}

.tasks__main-content {
  .nav-tabs .nav-link,
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    font-size: 12px;
    line-height: 18px;
  }
}

.tasks__right-nav {
  padding: 10px 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  img {
    width: 30px;
  }
}

.tasks__share-btn {
  border: 1px solid #cad5e0;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #91a4b7;
}

.tasks__search-box {
  width: 144px;
  .input-group-text {
    height: 32px;
  }
}

.tasks__text {
  padding: 10px 15px;
  padding-top: 0;
  .col-lg-8 {
    border-right: 1px solid $light-grey;
    min-height: 80.5vh;
  }
  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: $custom-grey;
  }
  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $custom-grey;
  }
}

.tasks__roles {
  padding: 15px;
  h4 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: $custom-grey;
  }
  .tasks__account-role {
    img {
      width: 30px;
    }
    .tasks__user {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: $custom-grey;
      display: block;
    }
    .tasks__role {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      color: $custom-grey;
      display: block;
    }
  }
}

.tasks__add-member-btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: $custom-grey;
  img {
    width: 30px;
    margin-right: 5px;
  }
}

.tasks__upcoming {
  padding: 15px;
  h4 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: $custom-grey;
  }
  img {
    width: 20px;
    margin-right: 5px;
  }
  th {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #91a4b7;
  }
  td {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: $custom-grey;
  }
  tbody {
    border-top: 1px solid $light-grey !important;
  }
}

.tasks__account-status {
  h4 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #ff708b;
  }
  select {
    color: #91a4b7;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    max-width: 130px;
    margin-left: auto;
  }

  .tasks__summary {
    border: 1px solid #cad5e0;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 15px;
    border-top: 10px solid #ff708b;
    h4 {
      color: $custom-grey;
    }
    h6,
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      line-height: 15px;
      color: $custom-grey;
    }
    p {
      font-weight: 400;
    }
    .tasks__summary-user {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      line-height: 15px;
      color: $custom-grey;
      img {
        width: 20px;
        margin-right: 5px;
      }
    }
  }
}

.tasks__timeline {
  .tasks__timeline-item {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: $custom-grey;
    img {
      width: 20px;
      margin-right: 5px;
    }
  }
  .tasks__separator {
    height: 70px;
    width: 1px;
    border-right: 1px dashed #91A4B7;
    margin: 0 auto;
  }
}
