.segments__rules-info {
  color: $blue;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  position: absolute;
}

.segments__tbody-hover tr {
  transition: ease-in-out 0.2s;
  cursor: pointer;

  .segments__button-wrapper button {
    visibility: hidden;
  }

  &:hover {
    background-color: #f6f8f8;

    .segments__button-wrapper button {
      visibility: visible;
    }
  }
}
.segments__rule-wrapper {
  border: 1px solid #e6e7e9;
  padding: 15px;
  border-radius: 10px;
  position: relative;

  select,
  input {
    max-width: 168px;
    width: auto;
  }
}

.segments__add-rule-btn {
  background: #cad5e0;
  border: 1px solid #cad5e0;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 6px 10px 6px 20px;
  color: $custom-grey;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.segments__rule-remove-btn {
  position: absolute;
  top: -5px;
  right: -5px;
  color: #91a4b7;
}

.segments__rules-separator {
  display: flex;
  align-items: center;
  .segments__rule-hr {
    background-color: $light-grey;
    height: 1px;
    width: 100%;
  }

  span {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $custom-grey;
    margin-right: 10px;
  }
}
